import React from "react"
import Template from "@components/pageFunctionalities/pageContactCenterIA/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Plataforma cloud con IA para Contact Center  [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/contact-center-campaign/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Accede a la plataforma ideal para Contact Center con inteligencia artificial y tecnología omnicanal."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Plataforma cloud con IA para Contact Center "
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/contact-center-campaign/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Accede a la plataforma ideal para Contact Center con inteligencia artificial y tecnología omnicanal."
      />
    </Helmet>
    <Template location={location} />
  </div>
)

export default IndexPage
