import React from "react"


export const TitleUnderlineStartOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="239" height="10" viewBox="0 0 239 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.4174 9.25243C39.4812 8.34061 3.31019 9.22194 0.682278 8.94341C-1.46267 8.57445 1.98963 2.21206 3.98512 1.28003C4.6846 0.944925 5.49603 0.898525 7.35418 1.05798C10.3004 1.35596 48.4107 0.910373 48.4107 0.910373C48.9132 1.10832 50.4251 0.450761 50.7015 0.690911C51.5724 1.252 113.343 0.693826 118.611 0.680424C121.805 0.67249 119.278 1.04454 123.037 0.9035C131.047 0.623039 148.504 0.974721 154.574 0.644957C159.432 0.372564 160.48 0.363738 165.141 0.612128C169.905 0.836318 195.24 0.61878 198.137 0.180463C198.846 0.0600983 199.39 0.104827 199.39 0.245918C199.39 0.27659 217.247 0.439413 217.329 0.550101C217.462 0.69163 218.006 0.760888 218.51 0.664387C219.445 0.520225 238.454 0.0488449 238.751 0.460825C239.681 1.64169 237.045 6.65103 234.833 7.86454C232.569 9.16375 220.169 7.65698 210.195 8.40949L163.769 8.33705L153.816 8.79517C152.678 8.36816 148.289 9.42117 145.642 8.65182C144.524 8.34756 136.617 8.2787 134.87 8.59196C133.35 8.83236 92.9021 8.08018 88.7505 8.91926C81.3593 8.18343 51.3702 9.10345 44.4174 9.25243Z" fill="#FFA700" />
    </svg>

  </span>
)

export const TitleUnderlineStartTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="149" height="10" viewBox="0 0 149 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.53 10.0076C24.4717 9.10196 2.05151 10.0284 0.423083 9.75311C-0.905878 9.38682 1.2424 3.02015 2.48047 2.08564C2.91446 1.74967 3.41745 1.70226 4.56893 1.85939C6.3946 2.1537 30.0162 1.66062 30.0162 1.66062C30.3274 1.85794 31.2654 1.1985 31.4364 1.4383C31.9754 1.9983 70.2621 1.36314 73.5274 1.34317C75.5069 1.33126 73.9398 1.70646 76.2698 1.56073C81.2354 1.27029 92.0545 1.60021 95.8173 1.26289C98.8288 0.984438 99.4781 0.974307 102.367 1.21689C105.319 1.43514 121.022 1.18602 122.819 0.744097C123.258 0.622849 123.596 0.6669 123.595 0.80799C123.595 0.838662 134.663 0.979228 134.713 1.08981C134.796 1.23118 135.133 1.29976 135.445 1.20263C136.025 1.0573 147.808 0.56223 147.991 0.973839C148.566 2.15354 146.925 7.16614 145.553 8.3824C144.148 9.68443 136.464 8.19312 130.281 8.95806L101.506 8.94348L95.3367 9.41401C94.6317 8.98842 91.9102 10.0469 90.2708 9.28084C89.5781 8.97798 84.6773 8.91897 83.5942 9.23441C82.6514 9.4767 57.5827 8.77494 55.0084 9.61919C50.4283 8.89258 31.8396 9.84998 27.53 10.0076Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
       width="141" height="8" viewBox="0 0 141 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.2141 8C23.299 7.25735 1.95384 8.08646 0.402345 7.86265C-0.864304 7.56254 1.15991 2.27751 2.33565 1.49925C2.74777 1.21949 3.22655 1.17881 4.3235 1.30597C6.06287 1.54505 28.5535 1.07198 28.5535 1.07198C28.8504 1.23475 29.7413 0.685396 29.905 0.883775C30.4201 1.34666 66.8741 0.715913 69.9832 0.69048C71.868 0.67522 70.3771 0.99059 72.5953 0.863424C77.3225 0.609092 87.6254 0.853252 91.2071 0.563314C94.0738 0.324241 94.6919 0.314075 97.4434 0.507368C100.255 0.680314 115.207 0.431066 116.916 0.0597408C117.334 -0.0419921 117.655 -0.00638251 117.655 0.11061C117.655 0.136044 128.194 0.222513 128.243 0.314073C128.322 0.431066 128.643 0.487015 128.94 0.405629C129.491 0.283549 140.709 -0.15899 140.885 0.181815C141.437 1.15845 139.891 5.31932 138.588 6.33157C137.255 7.41502 129.934 6.19932 124.049 6.85041L96.6495 6.91654L90.7768 7.32347C90.1041 6.97249 87.5163 7.85756 85.9527 7.22682C85.2921 6.97757 80.6255 6.94196 79.5952 7.20647C78.6982 7.40994 54.8258 6.89618 52.3774 7.60323C48.0138 7.01318 30.3171 7.85757 26.2141 8Z" fill="#FFA700"/>
</svg>




  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="251" height="8" viewBox="0 0 251 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6648 8C41.4755 7.25735 3.47811 8.08646 0.716231 7.86265C-1.53858 7.56254 2.0648 2.27751 4.15779 1.49925C4.89141 1.21949 5.74371 1.17881 7.69644 1.30597C10.7928 1.54505 50.8292 1.07198 50.8292 1.07198C51.3579 1.23475 52.9438 0.685396 53.2351 0.883775C54.1521 1.34666 119.045 0.715913 124.58 0.69048C127.935 0.67522 125.281 0.99059 129.23 0.863424C137.645 0.609092 155.986 0.853252 162.362 0.563314C167.465 0.324241 168.565 0.314075 173.463 0.507368C178.469 0.680314 205.084 0.431066 208.127 0.0597408C208.871 -0.0419921 209.443 -0.00638251 209.443 0.11061C209.443 0.136044 228.204 0.222513 228.291 0.314073C228.431 0.431066 229.003 0.487015 229.531 0.405629C230.513 0.283549 250.483 -0.15899 250.796 0.181815C251.777 1.15845 249.026 5.31932 246.707 6.33157C244.333 7.41502 231.301 6.19932 220.825 6.85041L172.05 6.91654L161.596 7.32347C160.398 6.97249 155.791 7.85756 153.008 7.22682C151.832 6.97757 143.525 6.94196 141.691 7.20647C140.094 7.40994 97.5977 6.89618 93.2391 7.60323C85.4714 7.01318 53.9687 7.85757 46.6648 8Z" fill="#FFA700" />
    </svg>
  </span>
)
export const TitleUnderlineTestimonyOne = ({ underline }) => (
  <span>
    {underline}
    <svg  style={{ bottom: "-2px" }} width="171" height="11" viewBox="0 0 171 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.7916 11C28.2562 9.97886 2.36955 11.1189 0.48795 10.8111C-1.0482 10.3985 1.4067 3.13158 2.8326 2.06147C3.3324 1.6768 3.91305 1.62086 5.24339 1.79572C7.35284 2.12444 34.6287 1.47397 34.6287 1.47397C34.9888 1.69778 36.0693 0.942419 36.2677 1.21519C36.8925 1.85166 81.1027 0.98438 84.8732 0.949409C87.1591 0.928427 85.351 1.36206 88.0411 1.18721C93.7741 0.837501 106.269 1.17322 110.613 0.774556C114.089 0.445832 114.839 0.431854 118.176 0.697631C121.586 0.935431 139.719 0.592715 141.792 0.0821436C142.299 -0.0577392 142.688 -0.00877595 142.688 0.152089C142.688 0.18706 155.47 0.305956 155.529 0.43185C155.624 0.592715 156.014 0.669646 156.374 0.55774C157.043 0.38988 170.648 -0.218611 170.861 0.249996C171.53 1.59287 169.655 7.31407 168.075 8.7059C166.458 10.1957 157.579 8.52407 150.443 9.41932L117.213 9.51025L110.091 10.0698C109.275 9.58717 106.137 10.8042 104.24 9.93688C103.439 9.59416 97.7798 9.5452 96.5303 9.90889C95.4425 10.1887 66.4909 9.48225 63.5215 10.4544C58.2295 9.64312 36.7675 10.8042 31.7916 11Z" fill="#FFBA00" />
    </svg>

  </span>
)

export const TitleUnderlineTestimonyTwo = ({ underline }) => (
  <span>
    {underline}
    <svg  style={{ bottom: "-2px" }} width="167" height="11" viewBox="0 0 167 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.0479 11C27.5953 9.97886 2.31412 11.1189 0.476536 10.8111C-1.02368 10.3985 1.37379 3.13158 2.76634 2.06147C3.25445 1.6768 3.82151 1.62086 5.12074 1.79572C7.18085 2.12444 33.8186 1.47397 33.8186 1.47397C34.1704 1.69778 35.2255 0.942419 35.4193 1.21519C36.0295 1.85166 79.2055 0.98438 82.8879 0.949409C85.1203 0.928427 83.3545 1.36206 85.9816 1.18721C91.5805 0.837501 103.783 1.17322 108.025 0.774556C111.421 0.445832 112.153 0.431854 115.412 0.697631C118.742 0.935431 136.451 0.592715 138.475 0.0821436C138.97 -0.0577392 139.351 -0.00877595 139.351 0.152089C139.351 0.18706 151.833 0.305956 151.891 0.43185C151.984 0.592715 152.364 0.669646 152.716 0.55774C153.369 0.38988 166.656 -0.218611 166.864 0.249996C167.517 1.59287 165.687 7.31407 164.144 8.7059C162.564 10.1957 153.893 8.52407 146.923 9.41932L114.471 9.51025L107.516 10.0698C106.719 9.58717 103.654 10.8042 101.802 9.93688C101.02 9.59416 95.4926 9.5452 94.2723 9.90889C93.2099 10.1887 64.9355 9.48225 62.0356 10.4544C56.8674 9.64312 35.9075 10.8042 31.0479 11Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineMarcaOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="251" height="8" viewBox="0 0 251 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.6648 8C41.4755 7.25735 3.47811 8.08646 0.716231 7.86265C-1.53858 7.56254 2.0648 2.27751 4.15779 1.49925C4.89141 1.21949 5.74371 1.17881 7.69644 1.30597C10.7928 1.54505 50.8292 1.07198 50.8292 1.07198C51.3579 1.23475 52.9438 0.685396 53.2351 0.883775C54.1521 1.34666 119.045 0.715913 124.58 0.69048C127.935 0.67522 125.281 0.99059 129.23 0.863424C137.645 0.609092 155.986 0.853252 162.362 0.563314C167.465 0.324241 168.565 0.314075 173.463 0.507368C178.469 0.680314 205.084 0.431066 208.127 0.0597408C208.871 -0.0419921 209.443 -0.00638251 209.443 0.11061C209.443 0.136044 228.204 0.222513 228.291 0.314073C228.431 0.431066 229.003 0.487015 229.531 0.405629C230.513 0.283549 250.483 -0.15899 250.796 0.181815C251.777 1.15845 249.026 5.31932 246.707 6.33157C244.333 7.41502 231.301 6.19932 220.825 6.85041L172.05 6.91654L161.596 7.32347C160.398 6.97249 155.791 7.85756 153.008 7.22682C151.832 6.97757 143.525 6.94196 141.691 7.20647C140.094 7.40994 97.5977 6.89618 93.2391 7.60323C85.4714 7.01318 53.9687 7.85757 46.6648 8Z" fill="#FFA700" />
    </svg>
  </span>
)
export const TitleUnderlineMarcaTwo = ({ underline }) => (
  <span>
    {underline}
    <svg  style={{ bottom: "-2px" }} width="167" height="11" viewBox="0 0 167 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.0479 11C27.5953 9.97886 2.31412 11.1189 0.476536 10.8111C-1.02368 10.3985 1.37379 3.13158 2.76634 2.06147C3.25445 1.6768 3.82151 1.62086 5.12074 1.79572C7.18085 2.12444 33.8186 1.47397 33.8186 1.47397C34.1704 1.69778 35.2255 0.942419 35.4193 1.21519C36.0295 1.85166 79.2055 0.98438 82.8879 0.949409C85.1203 0.928427 83.3545 1.36206 85.9816 1.18721C91.5805 0.837501 103.783 1.17322 108.025 0.774556C111.421 0.445832 112.153 0.431854 115.412 0.697631C118.742 0.935431 136.451 0.592715 138.475 0.0821436C138.97 -0.0577392 139.351 -0.00877595 139.351 0.152089C139.351 0.18706 151.833 0.305956 151.891 0.43185C151.984 0.592715 152.364 0.669646 152.716 0.55774C153.369 0.38988 166.656 -0.218611 166.864 0.249996C167.517 1.59287 165.687 7.31407 164.144 8.7059C162.564 10.1957 153.893 8.52407 146.923 9.41932L114.471 9.51025L107.516 10.0698C106.719 9.58717 103.654 10.8042 101.802 9.93688C101.02 9.59416 95.4926 9.5452 94.2723 9.90889C93.2099 10.1887 64.9355 9.48225 62.0356 10.4544C56.8674 9.64312 35.9075 10.8042 31.0479 11Z" fill="#FFBA00" />
    </svg>
  </span>
)